import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Link, Switch, Route, useHistory } from 'react-router-dom';
import { Navbar, Nav, Container, Form, Button } from 'react-bootstrap';
import { UserContext, Login, Logout, OauthCallback, AuthenticationProvider } from './auth.js';
import { Flash } from './flash';
import { ThemeContext, ThemeProvider, ThemeSwitcher } from './theme.js';
import { AddNode, EditNode, DeleteNode, ViewNode, SearchNodes, Main } from './app';

function LoginNavigation(props) {
	if (!props.value.is_logged_in) {
		return <Nav.Link as={Link} to="/login">Login</Nav.Link>
	}
	return <>
		<Nav.Link as={Link} to="/add">Add</Nav.Link>
		<Nav.Link as={Link} to="/logout">Logout [{props.value.username}]</Nav.Link>
	</>;
}

function SearchBox(props) {
	const [query, setQuery] = useState('');
	let history = useHistory();
	function on_search_change(event) {
		setQuery(event.target.value);
	}
	function on_submit(event) {
		event.preventDefault();
		//this.setState({search: true});
		//console.log('this', this);
		history.push('/search?q=' + encodeURIComponent(query));
	}
	if (!props.value.is_logged_in) {
		return '';
	}
	return <Form inline onSubmit={on_submit} action="/search">
		<Form.Control onChange={on_search_change} name="q" />
		<Button variant="secondary">Search</Button>
	</Form>;
}

function Navigation() {
	return <ThemeContext.Consumer>
		{theme => (
		<Navbar bg={theme.bg} variant={theme.bg} expand="lg">
			<Navbar.Brand as={Link} to="/">Unmess</Navbar.Brand>
			<Navbar.Toggle aria-controls="navbar-content" />
			<Navbar.Collapse id="navbar-content">
				<Nav className="mr-auto">
					<Nav.Link as={Link} to="/">Home</Nav.Link>
					<UserContext.Consumer>
						{value => <LoginNavigation value={value} />}
					</UserContext.Consumer>
				</Nav>
				<UserContext.Consumer>
					{value => <SearchBox value={value} />}
				</UserContext.Consumer>
				<ThemeSwitcher />
			</Navbar.Collapse>
		</Navbar>)}
	</ThemeContext.Consumer>;
}

function Router() {
	return <BrowserRouter>
		<Navigation />
		<Flash />
		<Container>
			<Switch>
				<Route path="/login" component={Login} />
				<Route path="/logout" component={Logout} />
				<Route path="/oauth-callback" component={OauthCallback} />
				<Route path="/add" component={AddNode} />
				<Route path="/node/:id/edit" component={EditNode} />
				<Route path="/node/:id/delete" component={DeleteNode} />
				<Route path="/node/:id" component={ViewNode} />
				<Route path="/search" component={SearchNodes} />
				<Route path="/" component={Main} />
			</Switch>
		</Container>
	</BrowserRouter>;
}

function Root() {
	return <AuthenticationProvider>
		<ThemeProvider>
			<Router />
		</ThemeProvider>
	</AuthenticationProvider>;
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);
