import React from 'react';
import { Button } from 'react-bootstrap';

const ThemeContext = React.createContext();

const themes = {
	'flatly': {
		css: '/bootstrap.flatly.min.css',
		bg: 'light',
	},
	'darkly': {
		css: '/bootstrap.darkly.min.css',
		bg: 'dark',
	},
};

class ThemeProvider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			theme: localStorage.getItem('theme') || 'flatly',
		};
		this.switch_theme = this.switch_theme.bind(this);
	}
	componentDidMount() {
		this.set_theme();
	}
	componentDidUpdate() {
		this.set_theme();
	}
	render() {
		return <ThemeContext.Provider value={{...themes[this.state.theme], switch_theme: this.switch_theme}}>
			{this.props.children}
		</ThemeContext.Provider>;
	}
	set_theme() {
		let e = document.getElementById('theme-stylesheet');
		const theme = themes[this.state.theme];
		e.href = theme.css;
		localStorage.setItem('theme', this.state.theme);
	}
	switch_theme() {
		const new_theme = (this.state.theme === 'flatly') ? 'darkly' : 'flatly';
		this.setState({theme: new_theme});
	}
}

class ThemeSwitcher extends React.Component {
	static contextType = ThemeContext;
	render() {
		return <Button size='sm' variant={this.context.bg} onClick={this.context.switch_theme}>Switch theme</Button>;
	}
}

export { ThemeContext, ThemeProvider, ThemeSwitcher };
