import React from 'react';
import { Container } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';

class Flash extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			messages: [],
		}
		this.seq = 0;
	}
	componentDidMount() {
		window.flash = this.flash.bind(this);
	}
	componentWillUnmount() {
		window.flash = null;
	}
	render() {
		if (this.state.messages.length === 0) {
			return '';
		}
		let that = this;
		const messages = this.state.messages.map(function(message, index) {
			return <Toast key={message.seq} onClose={() => that.remove(message.seq)} autohide={true} delay={(index+1)*3000}>
				<Toast.Header><strong className="mr-auto">{message.header || 'Unmess'}</strong></Toast.Header>
				<Toast.Body>{message.text}</Toast.Body>
			</Toast>;
		});
		return <Container style={{position: 'absolute', top: 0, right: 0}}>
			{messages}
		</Container>;
	}
	remove(seq) {
		this.setState(function(state, props) {
			const messages = state.messages.filter((message) => message.seq !== seq);
			return { messages };
		});
	}
	flash(message) {
		message.seq = ++this.seq;
		this.setState(function(state, props) {
			const messages = [...state.messages, message];
			return { messages };
		});
	}
}

export { Flash };
